import flatpickr from "flatpickr";

window.flatpickr = flatpickr;

import "flowbite";

require("./bootstrap");

//require('alpinejs');
import Alpine from "alpinejs";

window.Alpine = Alpine;

// store to store data online
Alpine.store("selectedItem", {
    data: null,
    modelType: null,
    isModalShow: false,
    setData(project) {
        this.data = project;
    },
    resetData() {
        this.data = null;
        this.isModalShow = false;
    },
    get getName() {
        return this.data && this.data.name ? this.data.name : null;
    },
});

Alpine.store("selectedProject", {
    project: null,
});
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
window.Swal = Swal;

import Iodine from "@caneara/iodine";

window.Iodine = new Iodine();

import { DateTime } from "luxon";

window.DateTime = DateTime;

import { Modal } from "flowbite";

window.Modal = Modal;

//window.Alpine.start()
(window.Dropzone = require("dropzone").default).autoDiscover = false;

require("web-animations-js");


//window.ClassicEditor = require('@ckeditor/ckeditor5-build-classic')
//window.ClassicEditor = require('ckeditor5-build-classic-complete')
import { ClassicEditor, editorConfig } from "./ckeditor";

window.ClassicEditor = ClassicEditor;
window.editorConfig = editorConfig;

import flatpckr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

//import pdfMake from "pdfmake/build/pdfmake";
//import pdfFonts from "pdfmake/build/vfs_fonts";
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

/* From pdfMake 0.2.15 and up: */
import pdfMake from "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

window.pdfMake = pdfMake;

import htmlToPdfmake from "html-to-pdfmake";

window.htmlToPdfmake = htmlToPdfmake;

let getDataTransfer = () => new DataTransfer();
const { concat } = Array.prototype;

try {
    getDataTransfer();
} catch {
    getDataTransfer = () => new ClipboardEvent("").clipboardData;
}

function createFileList() {
    // eslint-disable-next-line prefer-rest-params
    const files = concat.apply([], arguments);
    let index = 0;
    const { length } = files;

    const dataTransfer = getDataTransfer();

    for (; index < length; index++) {
        dataTransfer.items.add(files[index]);
    }

    return dataTransfer.files;
}

window.createFileList = createFileList;

function isValidUrl(string) {
    const pattern = new RegExp(
        "^(https:\\/\\/)" + // protocol (http:// or https://)
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}))" + // IP (v4) address
            "(\\:\\d+)?" + // port (optional)
            "(\\/[-a-z\\d%_.~+]*)*" + // path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string (optional)
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );

    return pattern.test(string);
}

window.isValidUrl = isValidUrl;

/***    vue?
 import { createApp } from 'vue'

 import NotificationButton from './components/NotificationButton.vue'
 export const app = createApp({})
 app.component('notification-button', NotificationButton)
 .mount('#app');
 ***/
Alpine.start();
